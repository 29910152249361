// Packages
import React, {
  FC,
  useState,
  useContext,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
// Contexts
import {
  LangsContext,
  ModalsContext,
} from 'contexts';
// Components
import {
  Button,
  SectionHeading,
  Logo,
  Skeleton,
  Langs,
} from 'components/ui';
import { Breadcrumbs } from "./components/Breadcrumbs";
// Utils
import {
  getLink,
} from 'utils';
// Data constants
import { DEFAULT_LANG } from 'constants/index';
import {
  headerData as staticHeaderData,
} from './Header.data';
import {
  buttonsData,
 } from 'commonData';
import './Header.sass';

declare var ym: any;

interface THeader {
  headerTitle?: string;
  headerDesc?: string;
  headerImage?: string;
  showBottom?: boolean;
  headerIcon?: string;
  headerBackground?: string;
  headerTheme?: string;
  isScoring?: boolean;
  isDocumentation?: boolean;
  productName?: string;
};

export const Header: FC<THeader> = ({
  headerTitle,
  headerDesc,
  headerImage,
  showBottom,
  headerIcon,
  headerBackground,
  headerTheme,
  isScoring,
  isDocumentation,
  productName,
}) => {
  const { lang } = useContext(LangsContext);
  const { showModal } = useContext(ModalsContext);
  const location = useLocation();
  const [isOpenNav, setIsOpenNav] = useState<boolean>(false);
  const headerData = staticHeaderData[lang] || staticHeaderData[DEFAULT_LANG];

  const renderNavItems = () => {
    return Object.keys(headerData.nav).map(key => {
      const navItem = headerData.nav[key];

      if (!navItem) return null;

      return (
        <li
          className="header__nav_item"
          key={key}
        >
          {getLink(
            navItem.isRouterLink,
            navItem.link,
            {
              className: 'header__nav_link',
              target: navItem.target,
              rel: navItem.rel,
            },
            lang,
            <>{navItem[lang].name}</>,
          )}

          {navItem.dropDown && (
            <ul className="header__nav_dropdown">
              {Object.keys(navItem.dropDown).map(key => {
                const dropdownItem = navItem.dropDown![key];

                if (!Object.keys(dropdownItem).length) {
                  return null;
                }

                return (
                  <li
                    className="header__nav_dropdownItem"
                    key={key}
                  >
                    {getLink(
                      dropdownItem.isRouterLink,
                      dropdownItem.link,
                      {
                        className: 'header__nav_dropdownLink',
                        target: dropdownItem.target,
                        'data-id': key
                      },
                      lang,
                      (<>
                        <h3>{dropdownItem[lang].name}</h3>
                        <p>{dropdownItem[lang].desc}</p>
                      </>)
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </li>
      )
    });
  };



  return (
    <header
      className={`header ${headerTheme ? `header_theme_${headerTheme}` : ''}`}
      style={{
        backgroundImage: headerBackground && `url(${headerBackground})`,
        backgroundSize: isScoring ? '100% auto' : '100% 100%',
        backgroundPosition: isScoring ? 'center calc(100% + 55px)' : 'center',
      }}
    >
      <div className="header__top">
        <div className="container header__top_flex">
          <button
            className="header__navShow"
            title="Показать меню"
            onClick={() => setIsOpenNav(true)}
          >
            <span />
          </button>
          <div className="header__homeWrp">
            <Link to="/" className="header__home">
              <Logo color={headerTheme === 'white' ? 'white' : '#1D2C62'} />
            </Link>
            <Langs type="dropdown" />
          </div>
          <div
            className={classNames('header__navWrp', {
              header__navWrp_isOpen: isOpenNav
            })}
          >
            <div className="header__navWrp_top">
              <button
                className="header__navHide"
                title="Закрыть меню"
                onClick={() => setIsOpenNav(false)}
              />
              <Logo color="white" />
            </div>
            <nav className="header__nav">
              <ul className="header__nav_items">{renderNavItems()}</ul>
            </nav>
          </div>
          <Button
            classes="header__action"
            theme="red"
            onClick={() => {
                showModal('ContactsModal')
                ym(37623865,'reachGoal','contact_button1')
              }
            }
          >
            {(buttonsData[lang] || buttonsData[DEFAULT_LANG]).contacts}
          </Button>
        </div>
      </div>
      {showBottom && (
        <div className="header__bottom">
          <div className="container">
            <div className="header__breadcrumbs">
              {!!headerIcon && (
                <figure className="header__breadcrumbs_logo">
                  <img src={headerIcon} alt="Icon" />
                  <figcaption>{headerTitle}</figcaption>
                </figure>
              )}
              {!!productName &&
                <Breadcrumbs productName = {productName}
                             pathname={location.pathname}
                             lang={lang}/>}
            </div>
            {!isDocumentation &&
              <>
                <SectionHeading
                  heading={headerTitle}
                  subHeading={headerDesc}
                  subHeadingWidth={500}
                />
                {headerImage ? (
                  <figure className={`header__image ${isScoring ? 'header__imageScoring' : ''}`}>
                    <img src={headerImage} alt="Icon" />
                    <figcaption>{headerTitle}</figcaption>
                  </figure>
                  ) : (
                    <Skeleton
                      type="block"
                      className="header__image"
                      style={{
                        width: 979,
                        height: 529,
                        margin: '67px auto 0',
                      }}
                    />
                  )}
              </>
            }
          </div>
        </div>
      )}
    </header>
  );
}

Header.defaultProps = {
  isScoring: false,
  showBottom: true,
};