// Packanges
import React, {
  FC,
  ChangeEvent,
  ReactElement,
  useContext,
  useState,
} from 'react';
// Clients
import { axiosClient } from 'clients';
// Contexts
import {
  LangsContext,
} from 'contexts';
// Components
import {
  Logo,
  Input,
  Textarea,
  Button,
} from 'components/ui';
// Contacts data
import { DEFAULT_LANG } from 'constants/index';
import {
  contactsModalData,
  TField,
} from './ContactsModal.data';
import {
  buttonsData,
} from 'commonData';
import classes from './ContactsModal.module.sass';

declare var ym: any;

interface TContactsModal {
  show?: boolean;
  hideSelf: () => void | Function;
  showModal: Function;
}

export const ContactsModal: FC<TContactsModal> = ({
  show,
  hideSelf,
  showModal,
}) => {
  const { lang } = useContext(LangsContext);
  const contacts = contactsModalData[lang] || contactsModalData[DEFAULT_LANG];

  interface TformData {
    fields: Array<TField>;
    isValidForm: boolean;
  };
  const [formData, setFormData] = useState<TformData>({
    fields: contacts.fields,
    isValidForm: false,
  });

  // Helpers
  function validateForm(fields: Array<TField>): boolean {
    return fields
      .filter(field => field.isRequired)
      .every(field => field.isValid === true);
  }

  function resetForm(): void {
    const fields = formData.fields.map(field => ({
      ...field,
      value: '',
      isValid: false
    }));
    setFormData({ fields: [...fields], isValidForm: false });
  }

  // Handlers
  async function handleSubmitForm() {
    type Tdata = {
      [key: string]: string;
    };
    let data: Tdata = {
      page: document.title,
      name: '',
      phone: '',
      email: '',
      message: '',
    };

    try {
      formData.fields.forEach((field) => data[field.name] = field.value);
      await axiosClient.post(`/mail.php`, data);

      resetForm();
      showModal('SuccessModal');
    } catch (err) {
      console.log(err);
    }
  }

  function handeField(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: TField, ind: number): void {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    const value = target.value;
    const copyFields = [...formData.fields];

    if (field.isRequired) {
      copyFields[ind].isValid = !!field?.pattern?.test(value);
    }
    copyFields[ind].value = value;

    setFormData({
      fields: [...copyFields],
      isValidForm: validateForm(copyFields),
    });
  }

  // Renders
  function renderFields(): ReactElement | null {
    return <>
      {contacts.fields.map((field, ind) => {
        const idTagField = classes.contacts + field.id;
        const fieldProps = {
          name: field.name,
          id: idTagField,
          value: formData.fields[ind].value,
          onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handeField(e, field, ind),
        };

        return (
          <div
            key={field.id}
            className={classes.contacts__formRow}
          >
            <label htmlFor={idTagField}>
              {!!field.label && field.label}
            </label>
            {field.type === 'input' && <Input {...fieldProps} />}
            {field.type === 'textarea' && <Textarea {...fieldProps} />}
          </div>
        );
      })}
    </>
  }

  return (
    <div className={`${classes.contacts} ${show ? classes.contacts_open : ''}`}>
      <div className={classes.contacts__top}>
        <Logo color="white" />
        <button
          className={classes.contacts__close}
          onClick={hideSelf}
        />
      </div>
      <div className={classes.contacts__bottom}>
        <div className="container">
          <h3 className={classes.contacts__title}>{contacts.title}</h3>
          <div className={classes.contacts__flex}>
            <div className={classes.contacts__form}>
              {renderFields()}
              <div className={classes.contacts__formActions}>
                <Button
                  theme="white"
                  onClick={() => {
                      handleSubmitForm()
                      ym(37623865,'reachGoal','form_complete1')
                    }
                  }
                  disabled={!formData.isValidForm}
                >
                  {(buttonsData[lang] || buttonsData[DEFAULT_LANG]).send}
                </Button>
              </div>
            </div>
            <div className={classes.contacts__list}>
              <h4 className={classes.contacts__listTitle}>
                {contacts.items.title}
              </h4>
              <ul className={classes.contacts__listItems}>
                {contacts.items.list.map(({
                  id,
                  link,
                  desc,
                }) => (
                    <li
                      className={classes.contacts__listItem}
                      key={id}
                    >
                      <a href={link}>{link}</a>
                      <span>{desc}</span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ContactsModal.defaultProps = {
  show: true,
};